


















































































































































































































































/* eslint-disable */
import { getApiUrl } from '@/inc/app.config'
import { useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import VCheckbox from '@/components/form/VCheckbox.vue'
import axios from 'axios'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import moment from 'moment'
import { getCallbackUrl } from '@/inc/utils'
import { Devis } from '@/inc/types'

export default defineComponent({
  name: 'step-four',
  components: { VCheckbox, FeedbackMessage },
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { SET_DOWNLOAD } = useMutations('my', ['SET_DOWNLOAD'])
    const { download, i18nSuivi, i18n } = useState('my', [
      'download',
      'i18nSuivi',
      'i18n',
    ])
    const cstmEtude = ref(false)

    const devisUrl = ref('')
    const isUrlChecked = ref(false)
    var devisStatus = ref<any>()
    const montantRestant = ref<any>()
    const montantRestantDu = ref<any>()
    const isAccord = ref(false)
    // ERROR VARIABLE
    const errorMsg = ref(false)
    const errorMsgCstm = ref(false)
    const aucunDevis = ref(false)
    // CONST pour les numero de dossier
    const FOUR = ref('4')
    const FOURTYONE = ref('41')
    const FOURTYTWO = ref('42')
    const FOURTYTHREE = ref('43')
    const FOURTYFOUR = ref('44')
    const FOURTYFIVE = ref('45')
    const hasError = ref(false)
    const feedbackMessage = ref('')

    // AJOUT DU CONSENTEMENT DANS LE STORE
    /*if (_props.parentData.wS109Actions.length > 0) {
      for (let action in _props.parentData.wS109Actions) {
        if (_props.parentData.wS109Actions[action].typeAction == 'ACCORD') {
          isAccord.value = true
        }
      }
    }*/
    //Montant restant a payer
    if (_props.parentData.liste?.length > 0) {
      for (let devis in _props.parentData?.liste) {
        if (_props.parentData.liste[devis]?.codeInfo === '44_1') {
          montantRestant.value = _props.parentData.liste[devis]
        }
        if (_props.parentData.liste[devis]?.codeInfo === '44_2') {
          montantRestantDu.value = _props.parentData.liste[devis]
        }
      }
    }
    onBeforeMount(async () => {
      if (
        _props.parentData.codeStatut === FOURTYTWO.value ||
        _props.parentData.codeStatut === FOURTYFOUR.value
      ) {
        getDevis(
          _props.parentData.numDossier[0],
          _props.parentData.idPartenaire
        )
      }
    })

    const submitPayment = async () => {
      await axios
        .post(`${getApiUrl()}/works/estimate-payment`, {
          Ordre: _props.parentData.numDossier[0],
          PartenaireId: _props.parentData.idPartenaire,
          DevisType: 'DEVIS',
          RedirectUrl: `${getCallbackUrl(
            `/suivi-dossier/${_props.parentData.numDossier[0]}`
          )}`,
          Total: '100',
        })
        .then(response => {
          hasError.value = false
          if (response.status === 200) {
            window.location.href = response.data.checkout
          }
        })
        .catch(e => {
          //errorMsg.value = true
          hasError.value = true
          feedbackMessage.value = e.response.data.message
        })
    }
    const getDevis = async (ordre, idPartenaire) => {
      await axios
        .get(`${getApiUrl()}/works/estimate`, {
          params: {
            Ordre: ordre,
            PartenaireId: idPartenaire,
            DevisType: 'DEVIS',
          },
        })
        .then(response => {
          hasError.value = false
          if (response.status === 200) {
            devisStatus.value = response.data as Devis
            devisUrl.value = response.data.url
          }
        })
        .catch(e => {
          hasError.value = true
          feedbackMessage.value = e.response.data.message
        })
    }

    const validate = async () => {
      var dateToday = moment().format('DD/MM/YYYY')
      isAccord.value = true
      await axios
        .get(
          `${getApiUrl()}/works/confirmation?NumDossier=${
            _props.parentData.numDossier[0]
          }&DateAccord=${dateToday}&TypeAction=ACCORD`
        )
        .then(response => {
          hasError.value = false
          if (response.status === 200) {
            //console.log(res)
            isAccord.value = true
          }
        })
        .catch(error => {
          // TODO bypass le probleme NE PAS OUBLIER D ENLEVER CES INFOS
          //errorMsgCstm.value = true
          isAccord.value = false
          hasError.value = true
          feedbackMessage.value = error.response.data.message
        })
    }
    const downloadDevis = () => {
      var fileURL = devisUrl.value
      console.log(fileURL)
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'Devis.pdf')
      document.body.appendChild(fileLink)
      fileLink.click()
      SET_DOWNLOAD()
    }
    const colorGris = () => {
      if (!download.value) {
        return true
      }
      return false
    }

    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-four')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })
    return {
      submitPayment,
      i18n,
      i18nSuivi,
      cstmEtude,
      errorMsg,
      devisUrl,
      validate,
      downloadDevis,
      colorGris,
      devisStatus,
      errorMsgCstm,
      isUrlChecked,
      download,
      montantRestant,
      montantRestantDu,
      isAccord,
      defaultUrl: 'Pas de devis dans la valise SharePoint',
      FOUR,
      FOURTYONE,
      FOURTYTWO,
      FOURTYTHREE,
      FOURTYFOUR,
      FOURTYFIVE,
      hasError,
      feedbackMessage,
    }
  },
})
